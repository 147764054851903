import "core-js/modules/es.array.is-array";import "core-js/modules/es.array.map";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
provinceAndCityData,
regionData,
// provinceAndCityDataPlus,
// regionDataPlus,
CodeToText,
TextToCode } from
'element-china-area-data';


export var code2Text = function code2Text(val) {
  if (Array.isArray(val) && val.length > 0) {
    return val.map(function (i) {return i ? CodeToText[i] : '';});
  }
  return [val];
};

export var code2obj = function code2obj(arr) {return {
    province: arr[0],
    city: arr[1],
    region: arr[2] };};

// obj< province ,city, region > => [string, string, string]
export var getArea = function getArea(_ref) {var province = _ref.province,city = _ref.city,region = _ref.region;
  try {
    var ret = [];

    if (province) {
      ret.push(CodeToText[province] || province);
      if (city) {
        ret.push(CodeToText[city] || city);
        if (region) {
          ret.push(CodeToText[region] || region);
        }
      }
    }
    return ret;
  } catch (error) {
    return [province, city, region];
  }
};
// obj<area : [code,code,code]> => obj< province ,city, region >
export var formatObj = function formatObj(obj) {var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'area';
  var ret = obj[key] ? code2obj(obj[key]) : {};

  delete obj[key];
  return _objectSpread(_objectSpread({}, obj), ret);
};
// obj<String : code> => [code,code,code]
export var TextObj2code = function TextObj2code(_ref2) {var province = _ref2.province,city = _ref2.city,region = _ref2.region;
  var ret = [];

  if (province) {
    ret.push(TextToCode(province));
    if (city) {
      ret.push(TextToCode(city));
      if (region) {
        ret.push(TextToCode(region));
      }
    }
  }
  return ret;
};
// obj <String : str> => [code,code,code]
export var Obj2code = function Obj2code(_ref3) {var province = _ref3.province,city = _ref3.city,region = _ref3.region;
  var ret = [];

  if (province) {
    ret.push(province);
    if (city) {
      ret.push(city);
      if (region) {
        ret.push(region);
      }
    }
  }
  return ret;
};
export default {
  name: 'area-selector',
  components: {},
  props: {
    type: {
      type: String,
      "default": 'regionData' },

    value: {
      // type: Array,
      "default": '' },

    valueFormat: {
      type: String,
      "default": 'code' // code  text  all
    } },

  data: function data() {
    return {
      selectedOptions: this.value };

  },
  watch: {
    value: function value(val) {
      this.selectedOptions = val;
    },
    selectedOptions: {
      handler: function handler(val) {
        this.handleChange(val);
      },
      immediate: true } },


  computed: {
    options: function options() {
      switch (this.type) {
        case 'provinceData':
          return provinceAndCityData.map(function (i) {return {
              label: i.label,
              value: i.value };});

        case 'provinceAndCityData':
          return provinceAndCityData;
        default:
          return regionData;}

    },
    area: function area() {
      return this.getCodeObj();
    } },

  methods: {
    handleChange: function handleChange(value) {
      var text = [];

      if (value.length > 0) {
        text = this.code2Text(value);
      }
      this.$emit('input', value);
      this.$emit('change', text);
    },
    onSelectByHand: function onSelectByHand(v) {
      var text = [];

      if (v.length > 0) {
        text = this.code2Text(v);
      }
      this.$emit('onSelectByHand', text);
    },
    code2Text: function code2Text(val) {var _this = this;
      if (Array.isArray(val) && val.length > 0) {
        return val.map(function (i) {return _this.code2Text(i);});
      }
      return CodeToText[val];
    },
    setCode: function setCode(_ref4) {var province = _ref4.province,city = _ref4.city,region = _ref4.region;
      this.selectedOptions = Obj2code({ province: province, city: city, region: region });
    },
    setString: function setString(_ref5) {var province = _ref5.province,city = _ref5.city,region = _ref5.region;
      this.selectedOptions = TextObj2code({ province: province, city: city, region: region });
    },
    getCodeObj: function getCodeObj(arr) {
      var code = arr || this.selectedOptions;

      return code2obj(code);
    },
    getStringObj: function getStringObj(arr) {
      var code = this.code2Text(arr || this.selectedOptions);

      return code2obj(code);
    } } };